import styled from 'styled-components';
import { Layout } from '../components/Layout/Layout';
import { H1Heading, H2Heading, Paragraph } from '../components/StyleComponents';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Image from 'next/image';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import useTranslationLgs from '../hooks/i18n/useTranslation';

const images = [
  'IMG_2669.jpeg',
  'IMG_5659.jpeg',
  'IMG_5662.jpeg',
  'IMG_5665.jpeg',
  'IMG_5666.jpeg',
  'IMG_5668.jpeg',
  'IMG_5669.jpeg',
  'IMG_5672.jpeg',
  'IMG_5676.jpeg',
  'IMG_5677.jpeg',
  'IMG_5670.jpeg',
  'IMG_5671.jpeg',
  'IMG_5678.jpeg',
  'IMG_5656.jpeg',
  'IMG_5667.jpeg',
  'IMG_5660.jpeg',
  'IMG_2665.jpeg',
  'IMG_5679.jpeg',
];

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});

export default function Home() {
  const { tr } = useTranslationLgs();
  return (
    <Layout pageTitle={'Úvodní stránka'}>
      <ContentWrapper>
        <H1Heading>Penzion U Kašny - Česká Kamenice</H1Heading>
        <Paragraph>
          {tr(
            `index.index1`,
            `Rodinný penzion U Kašny se nachází přímo na náměstí historického městečka Česká Kamenice. Nabízíme dvoulůžkové
          až čtyřlůžkové pokoje s vlastní koupelnou, ledničkou, televizí a Wi-Fi. Rádi u nás ubytujeme i vaše čtyřnohé
          miláčky. Penzion se nachází u národního parku České Švýcarsko a tak je skvělým výchozím bodem pro mnoho
          výletů. Nezapomeňte se podívat na naše tipy na výlety po blízkém i vzdálenějším okolí.`,
          )}
        </Paragraph>
        <CarouselWrapper>
          <Carousel>
            {images.map((image, key) => {
              return (
                <ImageWrapper key={key}>
                  <Image
                    key={key}
                    src={`/images/rooms/${image}`}
                    layout="fill"
                    objectFit="cover"
                    loader={({ src, width, quality }) => {
                      return `https://aqohqiyflq.cloudimg.io/www.ubytovaniukasny.cz/${src}?width=${width}&q=${
                        quality || 75
                      }`;
                    }}
                  />
                </ImageWrapper>
              );
            })}
          </Carousel>
        </CarouselWrapper>
        <H2Heading>{tr(`index.t1`, `V okolí České Kamenice`)}</H2Heading>
        <Paragraph>
          {tr(
            `index.d1`,
            `je mnoho velmi zajímavých pozoruhodností. Česká Kamenice je umístěna blízko Českého Švýcarska a mnoho
          čedičových nebo pískovcových masivů zde svojí fantazií vykouzlilo překrásné přírodní úkazy.`,
          )}
        </Paragraph>
        <Tips>
          <Tip>
            <TipHeading>{tr(`index.tip1`, `Zřícenina hradu na Zámeckém vrchu`)}</TipHeading>
            <ImageWithTextWrapper>
              <img
                src={'https://aqohqiyflq.cloudimg.io/www.ubytovaniukasny.cz/data/img1.png?width=183&height=121'}
                style={{ width: '183px', height: '121px' }}
              />
              <TextContainer>
                <Text>
                  {tr(
                    `index.tip1Description`,
                    `Hrad na který nevede cesta... Toto už je slavná hláška, kterou jsem pravil, když jsme se poprvé
                  šplhali na zříceninu hradu ze 13. století. Nahoře je překráný výhled na Českou Kamenici. No a cesta
                  tam je :D`,
                  )}
                </Text>
                <Chips>
                  <Chip>1 km</Chip>
                  <Chip>{tr(`index.pěšky`, `pěšky`)}</Chip>
                </Chips>
              </TextContainer>
            </ImageWithTextWrapper>
          </Tip>

          <Tip>
            <TipHeading>{tr(`index.tip2`, `Kaple Narození Panny Marie`)}</TipHeading>
            <ImageWithTextWrapper>
              <img
                src={'https://aqohqiyflq.cloudimg.io/www.ubytovaniukasny.cz/data/img2.png?width=183&height=121'}
                style={{ width: '183px', height: '121px' }}
              />
              <TextContainer>
                <Text>
                  {tr(
                    `index.tip2Description`,
                    `Nádherná barokní památka v České Kamenici, datována roku 1736-1739. Nachází se malý kousek od penzionu
                  a určitě byste si jí neměli nechat ujít.`,
                  )}
                </Text>
                <Chips>
                  <Chip>400 m</Chip>
                  <Chip>{tr(`index.pěšky`, `pěšky`)}</Chip>
                </Chips>
              </TextContainer>
            </ImageWithTextWrapper>
          </Tip>

          <Tip>
            <TipHeading>{tr(`index.tip3`, `Vyhlídka Jehla`)}</TipHeading>
            <ImageWithTextWrapper>
              <img
                src={'https://aqohqiyflq.cloudimg.io/www.ubytovaniukasny.cz/data/img3.png?width=183&height=121'}
                style={{ width: '183px', height: '121px' }}
              />
              <TextContainer>
                <Text>
                  {tr(
                    `index.tip3Description`,
                    `Jehla, jak název napovídá, se chlubí svým majestátním hrotem do vysokých pater nad Českou Kamenicí.
                  Čedičový kopec v nadmořské výšce 487 metrů nabízí další překrásný pohled na Českou Kamenici.`,
                  )}
                </Text>
                <Chips>
                  <Chip>1.6 km</Chip>
                  <Chip>{tr(`index.pěšky`, `pěšky`)}</Chip>
                </Chips>
              </TextContainer>
            </ImageWithTextWrapper>
          </Tip>

          <Tip>
            <TipHeading>{tr(`index.tip4`, `Vyhlídky Ponorka a Žába`)}</TipHeading>
            <ImageWithTextWrapper>
              <img
                src={'https://aqohqiyflq.cloudimg.io/www.ubytovaniukasny.cz/data/img4.png?width=183&height=121'}
                style={{ width: '183px', height: '121px' }}
              />
              <TextContainer>
                <Text>
                  {tr(
                    `index.tip4Descriptiokn`,
                    `Pískovce, kterých je v této oblasti hodně, vykouzlily překrásné skalní vyhlídky. Zajímavé je, že jich
                  je na trase několik. Mezi jednotlivými pískovci jsou schody, a celkově to tam vypadá jak v pohádce.`,
                  )}
                </Text>
                <Chips>
                  <Chip>400 m</Chip>
                  <Chip>{tr(`index.pěšky`, `pěšky`)}</Chip>
                </Chips>
              </TextContainer>
            </ImageWithTextWrapper>
          </Tip>
        </Tips>
      </ContentWrapper>
    </Layout>
  );
}
const ImageWrapper = styled.div`
  position: relative;
  height: 500px;
`;
const CarouselWrapper = styled.div``;

const Tips = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const Tip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

const ImageWithTextWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

const TipHeading = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #461a1d;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
`;

const Chips = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const Chip = styled.div`
  display: flex;
  padding: 2px 20px;
  background-color: #2dac00;
  border-radius: 4px;
  color: white;
`;

const Text = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CenteredColumn = styled.div`
  display: flex;
  margin: auto;
  width: 100%;
  margin: auto;
  flex-direction: column;
`;

const Main = styled.main`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.875rem;
`;
const Homepage = styled.div``;
